<template>
	<div>
		<Loader :error="errorMessage" :loading="isPending" />
		<div class="d-flex align-center">
			<div class="font-weight-bold">Momsbelopp: </div>
			<ui-text-field v-model="vatAmount" type="number" class="px-2" />
			<div class="text-caption">(ursprungligt belopp: {{ $utils.format.price(totalTax) }})</div>
		</div>
		<v-row class="mt-6" style="height: 120px;">
			<v-col cols="4">
				<select-template @input="onInput" />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<ui-autocomplete v-if="getIsActiveCode(0)"
					v-model="formData.codes[0]"
					clearable
					class="pr-2"
					:items="getCompCodeList.A"
					item-value="intval"
					:label="getCodeNames.A"
					:item-text="itemText"
				/>
			</v-col>
			<v-col cols="4">
				<ui-autocomplete v-if="getIsActiveCode(1)"
					v-model="formData.codes[1]"
					clearable
					class="pr-2"
					:items="getCompCodeList.B"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.B"
				/>
			</v-col>
			<v-col cols="3">
				<ui-text-field v-model="formData.amount" dense type="number" value=" " label="Belopp"
					class="pr-2"
				>
					<template v-if="getTotal > 0" v-slot:append>
						<v-btn icon small @click="formData.amount = getTotal"><v-icon small>mdi-auto-fix</v-icon></v-btn>
					</template>
				</ui-text-field>
			</v-col>
			<v-col cols="12">
				<div class="d-flex justify-end">
					<div v-if="formData.index >= 0">
						<v-btn color="warning" text @click="onCancel">Avbryt</v-btn>
						<v-btn color="error" text @click="onDelete">Ta bort</v-btn>
					</div>
					<v-btn v-if="amountLeft > 0 " color="primary" text @click="onAdd">Lägg till</v-btn>
					<v-btn v-if="formData.index >= 0" color="primary" text @click="onSave">Spara</v-btn>
				</div>
			</v-col>
		</v-row>

		<v-data-table v-if="vat"
			:headers="getHeaders"
			:items="getVat"
			hide-default-footer
			disable-sort
			@click:row="onRowClick"
		>
			<template v-for="(head, i) in headers" v-slot:[`item.${head}`]="{ item }">
				<div :key="head" v-tooltip :content="item.codetexts[i]" :tabIndex="-1">
					{{ getCodeValue(head, item.codes[i]) }}
				</div>
			</template>
		</v-data-table>

		<v-row class="mt-8">
			<v-col cols="12">
				<div class="d-flex justify-end">
					<div>Kvar att kontera: <strong class="primary--text">{{ $utils.format.price(amountLeft) }} </strong></div>
				</div>
			</v-col>
		</v-row>

		<div class="text-right pt-4">
			<v-btn v-if="amountLeft === '0.00'" color="primary" text :loading="isPending" @click="updateVat">Spara</v-btn>
		</div>
		<Loader :error="updateError" :success="updateFeedbackMessage" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectTemplate from '@/components/select-template';

const defaultData = () => ({
	amount: null,
	codes: [null, null]
});

export default {
	components: {
		SelectTemplate
	},

	props: {
		invoiceId: {
			type: String,
			required: true
		},

		vat: {
			type: Object,
			required: true
		},

		totalTax: {
			type: Number,
			required: true
		},

		amountLeft: {
			type: String,
			required: true
		}
	},

	data: () => ({
		formData: null,
		vatAmount: null,
		updateFeedbackMessage: null,
		updateError: '',

		headers: ['A', 'B'],
		isPending: false,
		errorMessage: ''
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getCodeLengths', 'getCodeValue', 'getIsActiveCode']),
		...mapGetters('userStore', ['getUser', 'getCodePermissions']),

		getCompCodeList() {
			const prem = Object.entries(this.getCodelist).reduce((acc, [key, value]) => {
				return {
					...acc,
					[key]:  value.filter(code => this.checkPremission(key, code.intval)).length > 0
						? value.filter(code => this.checkPremission(key, code.intval)) : acc[key]
				};
			}, this.getCodelist);

			return  prem;
		},

		getHeaders() {
			return [
				this.getIsActiveCode(0) && {
					text: `${this.getCodeNames.A}`,
					value: 'A'
				}, this.getIsActiveCode(1) && {
					text: `${this.getCodeNames.B}`,
					value: 'B'
				}, {
					text: 'Belopp',
					value: 'amount'
				}];
		},

		getVat() {
			return this.vat.rows.map(row => {
				return {
					...row,
					A: row.codes[0],
					B: row.codes[1],
					amount: row.amount,
					codetexts: row.codetexts
				};
			});
		},

		getTotal() {
			let total = 0;

			this.vat.rows.map(row => total += parseFloat(row.amount));

			return  (this.vatAmount - total).toFixed(2);
		}
	},

	watch: {
		vatAmount(to) {
			this.$emit('on-update', (to - this.vat.total).toFixed(2));
		}
	},

	created() {
		this.formData = defaultData();
		this.vatAmount = this.vat.total;
	},

	methods: {

		onRowClick(row) {
			this.formData = {
				index: this.getVat.indexOf(row),
				codes: [row.A, row.B],
				amount: row.amount
			};
		},

		onAdd() {
			this.vat.rows.push(this.formData);
			this.formData = defaultData();
			this.onUpdate();
		},

		onSave() {
			this.vat.rows.push({
				amount: this.formData.amount,
				codes: [this.formData.codes[0] , this.formData.codes[1]]
			});

			this.onDelete();
			this.onUpdate();

		},

		onDelete() {
			this.vat.rows.splice(this.formData.index, 1);
			this.formData = defaultData();
		},

		onUpdate() {
			let total = 0;

			this.vat.rows.map(row => total += parseFloat(row.amount));
			this.$emit('on-update', `${(this.vatAmount  - total).toFixed(2) }`);
		},

		onCancel() {
			this.formData = defaultData();
		},

		checkPremission(key, value) {
			const perm = this.getCodePermissions[key];
			let isOk = false;

			perm.forEach(range => {
				if (value >= range[0] && value <= range[1]) {
					isOk = true;
				}
			});

			return isOk;
		},

		itemText(a) {
			return `${a.kod} - ${a.kodtext}`;
		},

		onInput(value) {
			this.formData.codes = value ? [value.codes[0], value.codes[1] ] : [] ;
		},

		async updateVat() {
			this.errorMessage = '';
			try {
				await this.$service.invoices.updateVatAmount(this.invoiceId, {
					...this.vat,
					total: parseFloat(this.vatAmount)
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwExeption: true
				});
				this.updateFeedbackMessage = ['Moms sparad'];
				this.$emit('on-save');
			} catch (error) {
				this.errorMessage = error.message;
			}
		}
	}
};
</script>
