<template>
	<div class="RegisterList">
		<div v-if="massActions" class="d-flex align-end">
			<v-select v-model="massAction" dense class="MassActionSelect mr-2" style="max-width: 200px;" :items="massActions"
				label="Massåtgärd"
			/>
			<v-btn small color="primary" :disabled="!massAction" @click="doMassAction">Kör</v-btn>
		</div>
		<Loader :error="errorMessage" />

		<v-data-table
			v-if="localPagination.noPagination"
			ref="table"
			:sort-by="['name']"
			:headers="headers"
			:items="getItems"
			:items-per-page="10"
			:footer-props="{
				'items-per-page-options': [25, 50, 75, 100]
			}"
			item-class="_className"
			@click:row="open"
		>
			<template v-slot:item.roles="{item}">
				<v-chip v-for="role in item.roles" :key="role.key" class="mr-2" small color="accent"
					outlined
				>
					{{ role.value }}
				</v-chip>
			</template>
		</v-data-table>

		<v-data-table v-else
			ref="table"
			v-model="selectedRows"
			:item-key="itemKey"
			:show-select="showSelect || Boolean(massActions)"
			:sort-by="localPagination.sortBy"
			:sort-desc="localPagination.sortDesc"
			multi-sort
			:headers="getHeaders"
			:page="localPagination.page"
			:items="getItems"
			:items-per-page="localPagination.pageSize"
			:footer-props="{
				'items-per-page-options': [25, 50, 75, 100],
				'show-current-page': true
			}"
			item-class="_className"
			:server-items-length="localPagination.total"
			@click:row="open"
			@update:sort-by="onUpdateSort"
			@update:sort-desc="onUpdateSortDesc"
			@update:items-per-page="onUpdateItemsPerPage"
			@update:page="onUpdatePage"
		>
			<template v-slot:header="{props}">
				<tbody v-if="hasFilters(props.headers)">
					<tr class="RegisterList-Filter-Row ">
						<td v-for="header in props.headers" :key="header.value">
							<v-row v-if="Array.isArray(header.filterObj)" no-gutters>
								<v-col v-for="(item, index) in header.filterObj" :key="index" cols="6" class="pa-0">
									<v-text-field v-if="item.type === 'text'"
										v-model="filter[header.value][index]"
										class="RegisterList-Filter-Input mr-2 pt-1 "
										desen
										hide-details
										:placeholder="item.placeholder ? item.placeholder : 'Sök'"
										style="max-width: 250px; padding-bottom: 9px;"
										clearable
										no-label
									/>
									<ui-datepicker v-if="item.type === 'date'"
										v-model="filter[header.value][index]"
										class="RegisterList-Filter-Input"
										:clean="true"
										:placeholder="item.placeholder"
										:allowed-dates="index > 0 ? filter[header.value][0] : null"
										clearable
										no-label
									/>
								</v-col>
							</v-row>
							<div v-else>
								<div v-if="header.filterObj" class="mb-1">
									<v-text-field v-if="'filterObj' in header && header.filterObj.type === 'text'"
										v-model="filter[header.value]"
										dense
										hide-details
										class="RegisterList-Filter-Input textField"
										style="max-width: 250px;"
										:placeholder="header.filterObj.placeholder ? header.filterObj.placeholder : 'Sök'"
										clearable
										no-label
									/>
									<div v-if="'filterObj' in header && header.filterObj.type === 'users'">
										<v-autocomplete
											v-model.number="filter[header.value]"
											clearable
											class="RegisterList-Filter-Input textField"
											dense
											:items="getUsers"
											item-text="_userName"
											item-value="id"
											no-label
										/>
									</div>
									<div v-if="'filterObj' in header && header.filterObj.type === 'group' && groups ">
										<v-autocomplete
											v-model="filter[header.value]"
											clearable
											class="RegisterList-Filter-Input textField"
											dense
											:items="groups"
											item-text="name"
											item-value="_id"
										/>
									</div>

									<ui-datepicker v-if="'filterObj' in header && header.filterObj.type === 'date'"
										v-model="filter[header.value]"
										:clean="true"
										class="RegisterList-Filter-Input"
										:placeholder="header.filterObj.placeholder"
										clearable
										no-label
									/>

									<v-autocomplete v-if="'filterObj' in header && header.filterObj.type === 'select'"
										v-model="filter[header.value]"
										class="RegisterList-Filter-Input textField"
										:placeholder="header.filterObj.placeholder"
										:items="header.filterObj.items"
										clearable
										no-label
										dense
									/>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</template>

			<template v-slot:item.rn="{item}">
				<ui-checkbox v-model="item.rn" no-label :item="item" @change="onChange" />
			</template>

			<template v-slot:item.ra="{item}">
				<ui-autocomplete v-model="item.ra" :value="item.ra" item-text="name" item-value="_id" clearable
					:items="disabledItems"
					:loading="isPendingtypes" no-label hide-details @change="onChange(item)"
				/>
			</template>

			<template v-slot:item.rc="{item}">
				<v-btn v-tooltip icon :content="item.rc ? item.rc : (item.comment ? item.comment : '-')" @click="onRcClick(item)">
					<v-icon small>mdi-note-outline</v-icon>
				</v-btn>
			</template>

			<template v-slot:item.Amounts.TaxInclusiveTotal.Amount="{item}">
				<td style="text-align: end;">
					{{ item.Amounts.TaxInclusiveTotal.Amount }}
				</td>
			</template>

			<template v-slot:item.meta.payment.paydate="{item}">
				<td style="text-align: end;">
					{{ item.meta.payment.paydate }}
				</td>
			</template>

			<template v-slot:item.amount="{item}">
				<td style="text-align: end;">
					{{ item.amount }}
				</td>
			</template>

			<template v-slot:item.paydate="{item}">
				<td style="text-align: end;">
					{{ item.paydate }}
				</td>
			</template>

			<template v-for="(head, i) in codeHeaders" v-slot:[`item.codes.${i}`]="{ item }">
				<div :key="head" v-tooltip :content="item.codetexts[i]" :tabIndex="-1">
					{{ getCodeValue(head, item.codes[i]) }}
				</div>
			</template>

			<template v-slot:item.meta.payment_status="{item}">
				<v-tooltip bottom>
					<template v-slot:activator="{on}">
						<div v-if="item.meta.payment_status === 'blocked' " style="display: flex; justify-content: center;" v-on="on">
							<v-icon small color="black">mdi-cancel</v-icon>
						</div>
					</template>
					<span>Blockerad</span>
				</v-tooltip>
			</template>

			<template v-slot:item.roles="{item}">
				<v-chip v-for="role in item.roles" :key="role.key" class="mr-2" small color="accent"
					outlined
				>
					{{ role.value }}
				</v-chip>
			</template>

			<template v-slot:item.meta.document_sub_type="{item}">
				<td>{{ item._document_sub_type }}</td>
			</template>
			<template v-slot:item.giro="{item}">
				<div v-if="!item.giro || item.giro.length === 0" class="pl-12">-</div>
				<div v-else-if="item.giro.length > 1">
					<v-select dense class="ui-select-list d-flex align-center justify-center" style="max-width: 160px;" :items="item.giro" item-value="id"
						item-text="paymentMethods" @click.stop.prevent
					/>
				</div>
				<div v-else class="pl-2">{{ item.giro[0].paymentMethods }}</div>
			</template>
		</v-data-table>

		<div v-if="massActions" class="d-flex align-end">
			<v-select v-model="massAction" dense class="MassActionSelect mr-2" style="max-width: 200px;" :items="massActions"
				label="Massåtgärd"
			/>
			<v-btn small color="primary" :disabled="!massAction" @click="doMassAction">Kör</v-btn>
		</div>

		<SidebarRight v-if="sidebarOpen && showSidebar" v-click-outside="onOutsideClick" :push-content="pushContent" :width="sidebarWidth" :sidebar-no-footer="sidebarNoFooter"
			@close="close"
		>
			<slot />
			<template v-slot:footer>
				<div class="d-flex justify-space-between">
					<v-btn color="primary" text :disabled="getNavigateToStatus.prevDisabled" @click="navigateTo(getNavigateToStatus.prevIndex)">
						<v-icon left>mdi-chevron-left</v-icon>
						Föregående
					</v-btn>
					<v-btn color="primary" text :disabled="getNavigateToStatus.nextDisabled" @click="navigateTo(getNavigateToStatus.nextIndex)">
						Nästa
						<v-icon right>mdi-chevron-right</v-icon>
					</v-btn>
				</div>
			</template>
		</SidebarRight>
	</div>
</template>

<script>
import SidebarRight from '@/components/sidebar-right';
import {mapGetters} from 'vuex';


const ACTIVE_ITEM_CLASSNAME = 'RegisterList-activeItem';
const BOLD_ITEM_CLASSNAME = 'RegisterList-boldItem';

export default {
	name: 'RegisterList',

	components: {
		SidebarRight
	},

	props: {
		itemKey: {
			type: String,
			default: 'id'
		},
		items: {
			type: Array,
			default: () => [],
			required: true
		},
		headers: {
			type: Array,
			default: () => [],
			required: true
		},
		sidebarWidth: {
			type: String,
			default: '50%'
		},
		sidebarNoFooter: {
			type: Boolean,
			default: false
		},
		pagination: {
			type: Object,
			required: false,
			default: () => ({
				noPagination: true
			})
		},
		pushContent: {
			type: Boolean,
			default: true
		},
		showSelect: {
			type: Boolean,
			default: false
		},
		noClick: {
			type: Boolean,
			default: false
		},

		value: {
			type: Array,
			default: () => ([])
		},

		showSidebar: {
			type: Boolean,
			default: true
		},

		massActions: {
			type: Array,
			default: null
		}
	},

	data: () => ({
		selectedRows: [],
		sidebarOpen: false,
		activeItem: undefined,
		localPagination: {
			page: null,
			pageSize: null,
			total: null,
			sort: []
		},

		codeHeaders: ['A', 'B', 'C', 'D', 'E', 'F','G', 'H', 'I', 'J'],

		errorMessage: '',

		massAction: null,
		isPendingtypes: false,
		types: [],

		filter: {},
		groups: null
	}),

	computed: {
		...mapGetters('accounting', ['getCodeValue']),
		...mapGetters('users', ['getUsers']),
		...mapGetters('userStore', ['isUserAllowedToHandleAttestRegister']),


		getHeaders() {
			return this.headers.map(header => {
				if (header.filter) {
					const obj = {
						...header,
						filterObj: header.filter
					};
					// eslint-disable-next-line no-unused-vars
					const {filter, ...newObj} = obj;

					return newObj;

				}

				const obj = {
					...header
				};
					// eslint-disable-next-line no-unused-vars
				const {filter, ...newObj} = obj;

				return newObj;
			});
		},

		getNavigateToStatus() {
			if (!this.activeItem) {
				return;
			}

			const index = this.getItems.findIndex(item => item[this.itemKey] === this.activeItem[this.itemKey]);
			const blockedIndexes = this.getItems.reduce((acc, curr, index) => curr._skipInStepper ? [...acc, index] : acc, []);
			let nextIndex = null;
			let tryNext = index + 1;
			let prevIndex = null;
			let tryPrev = index - 1;

			do
				if (blockedIndexes.includes(tryNext)) {
					tryNext++;
				} else if (tryNext >= this.getItems.length) {
					nextIndex = -1;
				} else {
					nextIndex = tryNext;
				}
			while (nextIndex === null);

			do
				if (blockedIndexes.includes(tryPrev)) {
					tryPrev--;
				} else if (tryPrev === 0) {
					prevIndex = -1;
				} else {
					prevIndex = tryPrev;
				}
			while (prevIndex === null);

			return {
				prevDisabled: prevIndex === -1,
				nextDisabled: nextIndex === -1,
				prevIndex: prevIndex,
				nextIndex: nextIndex
			};
		},

		getItems() {
			return this.items.map(item => {
				const className = {
					[ACTIVE_ITEM_CLASSNAME]: this.activeItem && this.activeItem[this.itemKey] === item[this.itemKey],
					[BOLD_ITEM_CLASSNAME]: item._isBold
				};

				return {
					...item,
					_className: className
				};
			});
		},

		disabledItems() {
			return this.types.map(item => ({
				...item,
				disabled: item.internal
			}));
		}
	},

	watch: {
		activeItem(to) {
			this.$emit('active-item-changed', to);
		},

		'pagination.total'(to) {
			this.localPagination.total = to;
		},

		selectedRows() {
			this.$emit('input', this.selectedRows);
		},

		value() {
			this.selectedRows = this.value;
		},

		filter: {
			deep: true,
			handler(to) {
				const filters = Object.entries(to).reduce((acc, [key, value]) => {
					let headerObj = this.getHeaders.find(header => header.value === key);

					this.errorMessage = '';

					if (Array.isArray(value)) {
						const values = value.map((item, index) => {
							const transformer = headerObj.filterObj  && 'transform' in headerObj.filterObj[index] ? headerObj.filterObj[index].transform : val => val;

							return transformer(item);
						});
						const date0 = new Date(value[0]);
						const date1 = new Date(value[1]);

						if (date0 > date1 && value[1] && value[1].length >= 10) {
							this.errorMessage = 'Datum fr.o.m måste komma efter datum t.o.m.';
						}

						return values.some(value => value !== null) ? { ...acc, [key]: values} : acc;
					}

					const transformer = headerObj.filterObj  && 'transform' in headerObj.filterObj ? headerObj.filterObj.transform : val => val;


					return value !== null ? { ...acc, [key]: transformer(value)} : acc;
				}, {});

				this.$emit('on-filter-update', filters);
			}
		}
	},

	async created() {
		this.localPagination = {...this.pagination};
		this.selectedRows = [...this.value];

		this.setDefaultValues();
		await this.fetchGroups();
		await this.fetchAttachmenttypes();
	},

	methods: {
		onOutsideClick(event) {
			if(event.target.className === 'v-application--wrap' || event.target.className.includes('container')) {
				this.activeItem = undefined;
				this.sidebarOpen = false;
			}
		},

		setDefaultValues() {
			this.headers.forEach(header => {
				if (Array.isArray(header.filter)) {
					this.$set(this.filter, header.value, []);

					header.filter.forEach((_, index) => {
						this.$set(this.filter[header.value], index, null);
					});

					return;
				}

				this.$set(this.filter, header.value, null);
			});
		},

		hasFilters(headers) {
			return headers.some(header => header.filterObj);
		},

		doMassAction() {
			this.$emit('mass-action', {
				items: this.selectedRows,
				type: this.massAction
			});
		},

		open(item) {
			if(this.noClick) {
				return;
			}

			if(this.activeItem && item.ID !== this.activeItem.ID) {
				this.activeItem = item;
				this.sidebarOpen = true;

				return;
			}

			this.notClose = false;
			this.activeItem = item;
			this.sidebarOpen = true;
		},

		close() {
			this.activeItem = undefined;
			this.sidebarOpen = false;
		},

		async fetchGroups() {
			this.groups = await this.$service.groups.list({});
		},

		async fetchAttachmenttypes() {
			this.types = await this.$service.attachment.getTypes({
				pagination: {
					pageSize: 9999,
					page: 1
				}
			}, {
				loading: val => {
					this.isPendingtypes = val;
				}
			});
		},

		onUpdateSort(value) {
			this.localPagination.sortBy = value;

			this.$emit('pagination-update', this.localPagination);
		},

		onUpdateSortDesc(value) {
			this.localPagination.sortDesc = value;

			this.$emit('pagination-update', this.localPagination);
		},

		onUpdateItemsPerPage(value) {
			this.localPagination.pageSize = value;

			this.$emit('pagination-update', this.localPagination);
		},

		onUpdatePage(value) {
			this.localPagination.page = value;

			this.$emit('pagination-update', this.localPagination);
		},

		onChange(item) {
			this.$emit('on-change', item);
		},

		onRcClick(item) {
			this.$emit('rc-click', item);
		},

		navigateTo(index) {
			this.activeItem = this.getItems[index];
		}
	}
};
</script>

<style lang="scss">
.RegisterList-activeItem td {
		color: var(--v-accent-base);
	}

	.RegisterList-boldItem td {
		font-weight: bold;
	}

	.RegisterList tbody tr {
		cursor: pointer;
	}

	.RegisterList-Filter-Input {
		margin-top: 2px;
		.v-text-field__details {
			display: none !important;
		}
	}

	.RegisterList-Filter-Row td {
		border-bottom: 1px solid #e0e0e0;
	}

	.v-text-field__slot {
		padding: 0px;
	}

	.ui-select-list .v-text-field.v-text-field--enclosed .v-text-field__details {
		display: none !important;

	}
	.textField .v-text-field__slot {
		padding: 2px 0px 3px 0px !important;
	}
	.textField .v-select__slot {
		padding: 2px 0px 3px 0px !important;

	}

</style>
