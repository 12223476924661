<template>
	<div>
		<div class="d-flex justify-start">
			<v-col cols="6">
				<!-- <ui-select v-model="activeCodelistId"
					:items="codeList"
					item-value="_id"
					item-text="name"
					:loading="isPending"
					no-label
				/> -->
				<!-- <ui-select v-model="activeCodepart"
					:items="getNames"
					return-object
					item-text="value"
					no-label
					clearable
					@click:clear="activeCodeList = []"
				/> -->
			</v-col>
			<v-col cols="6">
				<!-- <div class="d-flex justify-end" style="height: 48px;">
					<v-btn color="accent mr-2" @click="newDialogOpen = true">
						Ny Kodlista
					</v-btn>
				</div> -->
			</v-col>
		</div>

		<loader v-if="changeError" :error="changeError" />

		<RegisterList ref="registerList" :pagination="pagination" :items="activeCodeList" :headers="getHeaders"
			item-key="_id"
			:push-content="false"
			no-click
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
			@on-change="change"
			@rc-click="onRcClick"
		/>

		<Dialog
			v-if="rcDialog"
			title="Ändra kommentar"
			width="640px"
			:loading="isPending"
			@reject="rcDialog = false"
			@confirm="change(activeRC)"
		>
			<Loader v-if="changeError" :error="changeError" />
			<v-form ref="form">
				<ui-text-field v-model="activeRC.rc" label="Kommentar" />
			</v-form>
		</Dialog>
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import { mapGetters } from 'vuex';

export default {
	components: {
		RegisterList
	},

	data: () => ({
		activeCodelistId: null,

		filter: {},
		activeCodepart: {
			key: '',
			value: ''
		},
		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: [],
			sortDesc: []
		},

		activeCodeList: [],
		codeList: [],
		nameList: [],
		types: [],

		isPending: false,
		isPendingtypes: false,
		newDialogOpen: false,
		rcDialog: false,

		changeError: '',
		activeRC: null
	}),

	computed: {
		...mapGetters('accounting', ['getCodeNames', 'getIsActiveCode']),
		...mapGetters('userStore', ['isUserAllowedToHandleAttestRegister', 'isUserAllowedToHandleReferenceCodes']),

		getNames() {
			// eslint-disable-next-line no-unused-vars
			return Object.entries(this.getCodeNames).filter(([_, value], index) => value !== '' && this.getIsActiveCode(index)).map(([key, value]) => ({ key, value }));
		},

		getHeaders() {
			return [{
				text: 'Koddel',
				value: 'part',
				filter: {
					type: 'select',
					items: [{
						text: 'Ansvar',
						value: 'A'
					}, {
						text: 'Konto',
						value: 'B'
					}, {
						text: 'Verksamhet',
						value: 'C'
					}, {
						text: 'Aktivitet',
						value: 'D'
					}, {
						text: 'Projekt',
						value: 'E'
					}, {
						text: 'Objekt',
						value: 'F'
					}, {
						text: 'Fri',
						value: 'G'
					}, {
						text: 'Motpart',
						value: 'H'
					}]
				}
			},{
				text: 'Kod',
				value: 'code',
				filter: {
					type: 'text'
				}
			},{
				text: 'Text',
				value: 'text',
				filter: {
					type: 'text'
				}
			},{
				text: 'Från',
				value: 'from',
				filter:[{
					type: 'date',
					placeholder: 'fr.o.m.',
					transform: this.$utils.date.normalizeDate
				},{
					type: 'date',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				}]
			},{
				text: 'Till',
				value: 'to',
				filter:[{
					type: 'date',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				}]
			},{
				text: 'Krav på anteckning',
				value: 'rn'
			}, {
				text: 'Krav på bilaga',
				value: 'ra'
			},{
				text: 'Kommentar',
				value: 'rc'
			}];
		},

		disabledItems() {
			return this.types.map(item => ({
				...item,
				disabled: item.internal
			}));
		}
	},

	async created() {
		await this.fetchCode();
	},

	methods: {
		onRcClick(item) {
			this.activeRC = item;
			this.rcDialog = true;
		},

		setActiveItem(item) {
			this.activeCode = item;
		},

		onFilterUpdate(filter) {
			this.filter = filter;
			this.fetchCode();
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;
			this.fetchCode();
		},

		async change(item) {
			this.changeError = '';
			const payload = {
				ra: item.ra,
				rn: item.rn,
				rc: item.rc
			};

			try {
				await this.$service.codelists.updateCode(item._id, payload, {
					throwException: true
				});
			} catch (error) {
				this.changeError = error.message;
			}

			this.rcDialog = false;
			this.fetchCode();
		},

		async fetchCode() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.codelists.searchList({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					// ...(this.activeCodepart ? { 'part': this.activeCodepart.key  } : {}),
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			});

			this.pagination.total = data._paginationTotal;

			this.activeCodeList = data.map(c => ({
				...c,
				part: this.getCodeNames[c.part],
				from: `${c.from < 20999999 ? this.$utils.date.intToPretty(c.from): ''}`,
				to: `${c.to < 20999999 ? this.$utils.date.intToPretty(c.to): ''}`
			}));
		}
	}
};
</script>
