<template>
	<div>
		<Loader :error="errorMessage" :loading="isPending" />

		<v-row class="mb-4">
			<v-col cols="12">
				<label class="font-weight-medium text-subtitle-2">Tillgängliga kreditar</label>
				<v-data-table :headers="creditHeaders"
					:items="credits"
					:hide-default-footer="true"
				/>
			</v-col>
			<v-col cols="12">
				<v-row class="d-flex justify-center align-center">
					<v-col cols="5">
						<ui-select v-model="activeCreditId" label="Välj kredit" :items="credits" item-text="available_amount" item-value="_id" />
					</v-col>
					<v-col cols="5">
						<ui-text-field v-model.number="formData.amount" type="number" label="Belopp" />
					</v-col>
					<v-col cols="2">
						<v-btn color="primary" @click="addCredit"><v-icon left>mdi-plus</v-icon>Lägg till</v-btn>
					</v-col>
				</v-row>
			</v-col>
			<v-col v-if="invoiceCredits.length > 0" cols="6">
				<label class="font-weight-medium text-subtitle-2">Reseverade kreditar</label>
				<v-list dense nav class="pa-0">
					<v-list-item-group color="primary">
						<v-list-item v-for="credit, index in invoiceCredits" :key="index">
							<v-list-item-content>
								<v-list-item-title v-text="$utils.format.price(credit.amount)" />
							</v-list-item-content>
							<v-list-item-icon @click="deleteCredit(credit)">
								<v-icon>mdi-delete</v-icon>
							</v-list-item-icon>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-col>
			<v-col v-if="invoice.Amounts" cols="6">
				<v-row class="d-flex align-center justify-space-around">
					<v-col cols="6" class="pb-0">
						<div class="text-caption">Ursprungligt belopp</div>
						<div class="text-subtitle-2">{{ $utils.format.price(invoice.Amounts.Payable.Amount) }}</div>
					</v-col>

					<v-col cols="6" class="pb-0">
						<div class="text-caption">Kvar att betala</div>
						<div class="text-subtitle-2">{{ $utils.format.price(invoice.meta.payment.amount) }}</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		invoice: {
			type: Object,
			required: true,
			default: () => ({})
		}
	},

	data: () => ({
		formData: {
			amount: null
		},

		activeCreditId: null,

		errorMessage: '',
		isPending: false,
		invoiceCredits: [],
		credits: [],
		creditHeaders: [
			{ text: 'Ursprungligt belopp', value: 'original_amount', align: 'right'},
			{ text: 'Reserverat belopp', value: 'reserved_amount', align: 'right'},
			{ text: 'Nuvarande belopp', value: 'available_amount', align: 'right'},
			{ text: '', value: 'actions', sortable: false}
		]
	}),

	computed: {
		...mapGetters('userStore', ['getUserGroupId'])
	},

	watch: {
		'invoice._id'() {
			this.fetchCredits();
		}
	},

	created() {
		this.setData();
		this.fetchCredits();
	},

	methods: {
		setData() {
			this.invoiceCredits = [...this.invoice.meta.credits_reserved, ...this.invoice.meta.credits_used];
		},

		async addCredit() {
			try {
				await this.$service.suppliers.reserveCredit(this.activeCreditId, this.invoice._id, this.formData,{
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.fetchCredits();
				this.refetchInvoice();
				this.formData = {
					amount: null
				};
				this.activeCreditId = null;
				this.$emit('on-update');
			} catch (error) {
				this.errorMessage = error.message;
			}

		},

		async deleteCredit(credit) {
			try {
				await this.$service.suppliers.deleteCredit(credit.id, this.invoice._id, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.fetchCredits();
				this.refetchInvoice();
				this.$emit('on-update');
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async fetchCredits() {
			const data = await this.$service.suppliers.getCredits(this.invoice.meta.supplier_id, {
				throwException: true,
				loading: val => {
					this.isPending = val;
				}
			});

			this.credits = data.map(c => ({
				...c,
				original_amount: this.$utils.format.price(c.original_amount),
				reserved_amount: this.$utils.format.price(c.reserved_amount),
				available_amount: this.$utils.format.price(c.available_amount)
			}));
		},

		async refetchInvoice() {
			try {
				this.invoiceCredits = [];
				const data = await this.$service.invoices.get(this.invoice._id, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.invoiceCredits = [...data.meta.credits_reserved, ...data.meta.credits_used];
			} catch (error) {
				this.errorMessage = error.message;
			}
		}
	}
};


</script>
