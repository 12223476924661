<template>
	<v-form ref="form">
		<v-row>
			<v-col cols="4">
				<ui-autocomplete
					v-model="formData.uid"
					:items="getUsers"
					:rules="$validator('base/required')"
					label="Användare *"
					item-text="_userName"
					item-value="id"
				/>
			</v-col>
			<v-col cols="4">
				<ui-select
					v-model="formData.type"
					label="Attestroll *"
					:rules="$validator('base/required')"
					:items="types"
					item-text="name"
					item-value="value"
				/>
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.amount" label="Maxbelopp *" :rules="$validator('base/required')" />
			</v-col>
			<v-col cols="4">
				<ui-datepicker v-model="formData.from" label="Datum fr.o.m. *" clearable :rules="$validator('base/required')" />
			</v-col>
			<v-col cols="4">
				<ui-datepicker v-model="formData.to" label="Datum t.o.m." clearable />
			</v-col>
			<v-col cols="4" />
			<v-col v-if="getIsActiveCode(0)" cols="3">
				<ui-text-field v-model="formData.codes[0][0]" :disabled="getCodeRules(0) === false" :value="getPaddedNumber(0,0, getCodeLengths[0], '0')" :label="getCodeNames.A" :sub-label="`Från ${getCodeRules(0) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[0][1]" :disabled="getCodeRules(0) === false" :value="getPaddedNumber(0,1, getCodeLengths[0], '9')" :sub-label="`Till ${getCodeRules(0) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(1)" cols="3">
				<ui-text-field v-model="formData.codes[1][0]" :disabled="getCodeRules(1) === false" :value="getPaddedNumber(1,0, getCodeLengths[1], '0')" :label="getCodeNames.B" :sub-label="`Från ${getCodeRules(1) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[1][1]" :disabled="getCodeRules(1) === false" :value="getPaddedNumber(1,1, getCodeLengths[1], '9')" :sub-label="`Till ${getCodeRules(1) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(2)" cols="3">
				<ui-text-field v-model="formData.codes[2][0]" :disabled="getCodeRules(2) === false" :value="getPaddedNumber(2,0, getCodeLengths[2], '0')" :label="getCodeNames.C" :sub-label="`Från ${getCodeRules(2) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[2][1]" :disabled="getCodeRules(2) === false" :value="getPaddedNumber(2,1, getCodeLengths[2], '9')" :sub-label="`Till ${getCodeRules(2) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(3)" cols="3">
				<ui-text-field v-model="formData.codes[3][0]" :disabled="getCodeRules(3) === false" :value="getPaddedNumber(3,0, getCodeLengths[3], '0')" :label="getCodeNames.D" :sub-label="`Från ${getCodeRules(3) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[3][1]" :disabled="getCodeRules(3) === false" :value="getPaddedNumber(3,1, getCodeLengths[3], '9')" :sub-label="`Till ${getCodeRules(3) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(4)" cols="3">
				<ui-text-field v-model="formData.codes[4][0]" :disabled="getCodeRules(4) === false" :value="getPaddedNumber(4,0, getCodeLengths[4], '0')" :label="getCodeNames.E" :sub-label="`Från ${getCodeRules(4) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[4][1]" :disabled="getCodeRules(4) === false" :value="getPaddedNumber(4,1, getCodeLengths[4], '9')" :sub-label="`Till ${getCodeRules(4) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(5)" cols="3">
				<ui-text-field v-model="formData.codes[5][0]" :disabled="getCodeRules(5) === false" :value="getPaddedNumber(5,0, getCodeLengths[5], '0')" :label="getCodeNames.F" :sub-label="`Från ${getCodeRules(5) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[5][1]" :disabled="getCodeRules(5) === false" :value="getPaddedNumber(5,1, getCodeLengths[5], '9')" :sub-label="`Till ${getCodeRules(5) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(6)" cols="3">
				<ui-text-field v-model="formData.codes[6][0]" :disabled="getCodeRules(6) === false" :value="getPaddedNumber(6,0, getCodeLengths[6], '0')" :label="getCodeNames.G" :sub-label="`Från ${getCodeRules(6) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[6][1]" :disabled="getCodeRules(6) === false" :value="getPaddedNumber(6,1, getCodeLengths[6], '9')" :sub-label="`Till ${getCodeRules(6) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(7)" cols="3">
				<ui-text-field v-model="formData.codes[7][0]" :disabled="getCodeRules(7) === false" :value="getPaddedNumber(7,0, getCodeLengths[7], '0')" :label="getCodeNames.H" :sub-label="`Från ${getCodeRules(7) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[7][1]" :disabled="getCodeRules(7) === false" :value="getPaddedNumber(7,1, getCodeLengths[7], '9')" :sub-label="`Till ${getCodeRules(7) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(8)" cols="3">
				<ui-text-field v-model="formData.codes[8][0]" :disabled="getCodeRules(8) === false" :value="getPaddedNumber(8,0, getCodeLengths[8], '0')" :label="getCodeNames.I" :sub-label="`Från ${getCodeRules(8) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[8][1]" :disabled="getCodeRules(8) === false" :value="getPaddedNumber(8,1, getCodeLengths[8], '9')" :sub-label="`Till ${getCodeRules(8) === true ? '*': ''}` " />
			</v-col>
			<v-col v-if="getIsActiveCode(9)" cols="3">
				<ui-text-field v-model="formData.codes[9][0]" :disabled="getCodeRules(9) === false" :value="getPaddedNumber(9,0, getCodeLengths[9], '0')" :label="getCodeNames.J" :sub-label="`Från ${getCodeRules(9) === true ? '*': ''}` " />
				<ui-text-field v-model="formData.codes[9][1]" :disabled="getCodeRules(9) === false" :value="getPaddedNumber(9,1, getCodeLengths[9], '9')" :sub-label="`Till ${getCodeRules(9) === true ? '*': ''}` " />
			</v-col>
		</v-row>

		<div v-if="!readOnly" class="d-flex align-end justify-end mt-6">
			<v-btn color="accent" text @click="save">Spara</v-btn>
		</div>
	</v-form>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		value: {
			type: Object,
			default: () => ({})
		},

		infoAttest: {
			type: Object,
			default: () => ({})
		},

		readOnly: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		formData: {
			uid: null,
			type: null,
			from: null,
			to: null,
			amount: null,
			codes: [['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', '']]
		},

		formPostIsPending: false,

		types: [{name: 'Godkännare', value: 1}, {name: 'Beslutsattestant', value: 2}]
	}),

	computed: {
		...mapGetters('users', ['getUsers']),
		...mapGetters('accounting', ['getCodeNames', 'getCodelist', 'getCodeLengths', 'getIsActiveCode', 'getCodeRules'])
	},

	watch: {
		infoAttest: {
			deep: true,
			handler(to) {
				this.formData = {
					uid: to.uid,
					type: to.type,
					from: to.from,
					to: to.to ? to.to : '99999999',
					amount: parseInt(to.amount),
					codes: to.codes
				};
			}
		},

		formData: {
			deep: true,
			handler(to) {
				this.$emit('input', {
					uid: to.uid,
					type: to.type,
					from: this.$utils.date.dateToInt(to.from),
					to: to.to ? this.$utils.date.dateToInt(to.to) : '99999999',
					amount: parseInt(to.amount),
					codes: to.codes.map(a => a.map(s => !s ? 0 : parseInt(s)))
				});
			}
		}
	},

	created() {
		if(this.infoAttest.uid) {
			return this.formData =  {
				...this.infoAttest
			};
		}

		this.formData = {
			...this.value,
			...this.formData
		};

		this.$emit('input', {...this.formData});
	},

	methods: {
		validate() {
			return this.$refs.form.validate();
		},

		getPaddedNumber(subArrayIndex, numberIndex, codeLength, type) {
			const number = this.formData.codes[subArrayIndex][numberIndex].toString();

			if(number.includes('*')) {
				const l = codeLength - (number.length - 1);

				return this.$set(this.formData.codes[subArrayIndex], numberIndex, number.replace('*', type.repeat(l)));
			}

			return this.formData.codes[subArrayIndex][numberIndex];
		},

		save() {
			this.$emit('save');
		}
	}
};
</script>

