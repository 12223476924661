<template>
	<Dialog v-model="open" title="Ny Konversation" width="640" max-width="720" :retain-focus="false"
		@confirm="confirm"
		@reject="reject"
	>
		<v-container>
			<v-card-text>
				<v-form ref="form" v-model="formIsValid" :disabled="isPending">
					<ui-text-field v-model="formData.subject" :label="$i18n.t('base.subject')" :rules="$validator('base/required')" filled
						dense @keydown.enter="confirm"
					/>

					<div class="text-subtitle-2 font-weight-medium pb-1">Mottagare</div>
					<v-card elevation="0" class="mb-4" color="background" style="height: 200px; overflow-y: auto;"
						rounded
					>
						<v-treeview v-model="selectedRecipients" selectable :items="localGroups" return-object>
							<template v-slot:append="{ item }">
								<v-icon v-if="item.isUser">
									mdi-account
								</v-icon>
							</template>
						</v-treeview>
					</v-card>

					<div class="mb-10">
						<v-chip v-for="user in getRecipients" :key="user.id" color="primary" class="mr-1 mb-1" close
							@click:close="remove(user.id)"
						>
							{{ user.name }}
						</v-chip>
					</div>

					<v-row v-if="invoiceId" class="d-flex align-center">
						<v-col cols="6">
							<v-checkbox v-model="formData.requires_action" label="Kräver åtgärd" />
						</v-col>
						<v-col cols="6">
							<ui-datepicker v-model="formData.duedate" clearable label="Datum" />
						</v-col>
					</v-row>

					<ui-textarea v-model="formData.content" dense height="140" :label="$i18n.t('base.message')"
						:rules="$validator('base/required')" filled
					/>
				</v-form>

				<Loader :error="addError" />
			</v-card-text>
		</v-container>
	</Dialog>
</template>

<script>
import {mapGetters} from 'vuex';

import {run} from '@/utils/service';

export default {
	props:{
		invoiceId: {
			type: String,
			default: null
		}
	},

	data: () => ({
		open: true,

		selectedRecipients: [],
		addError: '',

		formIsValid: false,
		isPending: false,
		formData: {
			subject: '',
			content: '',
			requires_action: false,
			duedate: null,
			meta: {
				invoice: ''
			}
		},

		users: [],

		localGroups: []
	}),

	computed: {
		...mapGetters('userStore', ['getUserData']),
		...mapGetters('users', ['getUsers']),

		getRecipients() {
			return this.selectedRecipients.reduce((acc, curr) => {
				if (!curr.isUser || acc.find(x => x.realId === curr.realId) || curr.realId === this.getUserData.id) {
					return acc;
				}

				return [...acc, curr];
			}, []);
		}
	},

	async created() {
		run(await this.$service.users.get.call(), data => {
			this.users = data;
		});

		await this.fetchGroups();
	},

	methods: {
		reject() {
			this.$emit('close');
		},

		remove(id) {
			this.selectedRecipients = this.selectedRecipients.filter(x => x.id !== id);
		},

		async confirm() {
			this.addError = '';
			this.formData.recipients = this.getRecipients.map(x => x.realId);

			if (!this.$refs.form.validate()) {
				return;
			}

			if(this.invoiceId) {
				this.formData.meta.invoice = this.invoiceId;
			}

			try {
				const data = await this.$service.mail.add({
					...this.formData,
					duedate: this.$utils.date.dateToInt(this.formData.duedate),
					recipients: [...this.formData.recipients, this.getUserData.id]
				}, {
					loading: val => {
						this.isPending = val;
					}
				});

				this.$emit('close');
				this.$emit('mail-created', {...data});
			} catch (error) {
				this.addError = error.message;
			}
		},

		async fetchGroups() {
			run(await this.$service.groups.get.call(), data => {
				this.localGroups = this.createGroupTree(data);
			});
		},

		createGroupTree(groups) {
			const final = [];
			const findAndAdd = (children, group) => {
				children.forEach(node => {
					if (node.isUser) {
						return;
					}

					if (node.children && node.id === group.parent) {
						node.children = [...node.children, group];

						return;
					}

					findAndAdd(node.children, group);
				});
			};
			const bucket = groups.map(x => ({
				...x,
				children: x.users.map(user => ({
					...user,
					id: `${x.id}-${user.id}`,
					isUser: true,
					realId: user.id,
					name: this.getUsers.find(u => u.id === user.id)._userName
				}))
			}));

			bucket.forEach(group => {
				// Handle null parents (first level)
				if (!group.parent) {
					final.push(group);

					return;
				}

				findAndAdd(final, group);
			});

			return final;
		}
	}
};
</script>
