<template>
	<portal to="app">
		<v-slide-x-transition>
			<div :style="`width: ${width}; height: 100%;`" :class="inboxOpen ? 'ChatRight' : 'InvoicePreview'">
				<div v-if="mail._id && referenceMail" class="d-flex align-center" style="height: 65px;">
					<h3 class="headline ml-6">
						{{ `${name} ` }}<a style="text-decoration: underline;" @click="$router.push({ path: '/invoices/all', query: {
							invoice: activeInvoice,
							chat: true
						}})"
						>{{ `${mail.meta.invoice ? `${invoiceName}` : ''}` }}</a>
					</h3>
					<v-btn v-tooltip class="ml-auto" fab text
						small
						color="black"
						content="Användare"
						@click.stop="showRecipients = !showRecipients"
					>
						<v-icon>mdi-account-multiple</v-icon>
					</v-btn>
					<v-btn v-tooltip fab text
						small
						color="black"
						content="Bjud in användare"
						@click.stop="showUserAddDialog = true"
					>
						<v-icon>mdi-account-multiple-plus</v-icon>
					</v-btn>
					<v-btn v-tooltip fab text
						small
						color="black"
						content="Lämna konversation"
						@click="openLeaveConfirmDialog"
					>
						<v-icon>mdi-logout</v-icon>
					</v-btn>
					<!-- <v-btn v-tooltip fab text
						small
						color="grey"
						content="Arkivera"
						@click="openArchiveDialog"
					>
						<v-icon>mdi-package-down</v-icon>
					</v-btn> -->
					<v-btn
						fab text
						small
						color="black"
						@click="$emit('close')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<div v-else class="d-flex justify-end align-center mr-2" style="height: 65px;">
					<v-btn v-if="!invoice.meta.mail[0]" class="mr-2" color="accent" @click="newDialogOpen = true">
						<v-icon left>mdi-plus</v-icon>
						Ny Konversation
					</v-btn>
					<div v-else class="text-subtitle-2 mr-5">
						Konversation finns redan på fakturan,<br> kontakta {{ `${owner.name} (${owner.login})` }} för inbjudan.
					</div>
					<v-btn
						fab text
						small
						color="grey"
						@click="$emit('close')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<div v-if="mail._id && referenceMail" class="d-flex ml-6 pb-0">
					<div class="text-subtitle-2">{{ `Datum: ${$utils.date.intToPretty(referenceMail.date)}` }}</div>
					<div class="text-subtitle-2 ml-6">{{ `Kräver åtgärd: ${referenceMail.requires_action ? 'Ja' : 'Nej'}` }}</div>
				</div>
				<div v-if="showRecipients" class="py-6">
					<v-row>
						<v-col v-for="user in referenceMail.recipients" :key="user" cols="3" class="d-flex align-center justify-center">
							<v-avatar
								class="ml-5"
								size="48"
							>
								<img :src="getUsers.find(x => x.id === user).avatar">
							</v-avatar>
							<span class="text-caption ml-4">{{ `${getUsers.find(x => x.id === user).name} (${getUsers.find(x => x.id === user).login})` }}</span>
						</v-col>
					</v-row>
				</div>
				<div class="d-flex align-end pa-2" style="height: calc(100% - 100px); width: 100%">
					<div style="flex: 1; height: 100%;">
						<div ref="chatbox" class="pa-4 background rounded-xl" style="height: calc(100% - 120px); overflow-y: auto;" @scroll="onChatScroll">
							<div v-for="msg in mails" :key="msg._id" :set="systemMessage = getSystemMessage(msg)" style="padding: 5px 0;">
								<div v-if="systemMessage" class="text-center primaryFont--text text--darken-3" style="flex: 1;">{{ systemMessage }}</div>

								<v-container v-if="!msg._isYou && !systemMessage">
									<v-row>
										<v-col cols="1" class="d-flex align-center justify-center pb-0">
											<v-avatar size="48">
												<img v-if="getUserData.avatar" :src="getUserData.avatar">
											</v-avatar>
										</v-col>
										<v-col cols="11" class="d-flex align-center primary--text font-weight-small pb-0">
											{{ `${getUsers.find(x => x.id === msg.from_user).name} (${getUsers.find(x => x.id === msg.from_user).login})` }}
										</v-col>
									</v-row>

									<v-row>
										<v-col class="pt-0" cols="1" />
										<v-col class="pt-0" cols="7">
											<v-card>
												<v-card-text style="white-space: pre-line;" color="black--text">
													{{ msg.content }}
												</v-card-text>
											</v-card>
										</v-col>
										<v-col cols="1" class="d-flex align-center justify-center primaryFont--text text--darken-3 pt-0">
											<em>{{ msg._time }}</em>
										</v-col>
										<v-col class="pt-0" cols="1" />
									</v-row>
								</v-container>

								<v-container v-if="msg._isYou && !systemMessage">
									<v-row>
										<v-col cols="3" />
										<v-col cols="1" class="d-flex align-center justify-center primaryFont--text text--darken-3">
											<em>{{ msg._time }}</em>
										</v-col>
										<v-col cols="7">
											<v-card :class="getCardClasses(msg)">
												<v-card-text style="white-space: pre-line;" class="black--text">
													{{ msg.content }}
												</v-card-text>
											</v-card>
										</v-col>
										<v-col cols="1" />
									</v-row>
								</v-container>
							</div>
						</div>

						<div v-if="mail._id && referenceMail" class="pt-8" style="height: 120px;">
							<Loader :error="errorMessage" hide-loading slim />
							<v-row>
								<v-col cols="2" class="d-flex justify-center align-center pa-0">
									<v-menu v-model="emojiMenu" :close-on-content-click="false" :nudge-width="200" offset-y top
										nudge-top="10"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn fab small elevation="0" color="primary" v-bind="attrs"
												v-on="on"
											>
												<v-icon>mdi-emoticon</v-icon>
											</v-btn>
										</template>
										<v-card class="pa-6" max-width="600">
											<span v-for="(e, index) in emojis" :key="index" class="ma-1 d-inline-block" style="cursor: pointer;" @click="insertEmoji(e)">{{ e }}</span>
										</v-card>
									</v-menu>
								</v-col>
								<v-col cols="8" class="d-flex justify-center align-center pa-0">
									<v-textarea
										v-model="message"
										class="ChatMessage-textarea"
										filled
										rounded
										autogrow
										no-resize
										dense
										height="70"
										label="Meddelande.."
										@keydown.enter.exact.prevent="sendMessage()"
										@keydown.enter.shift.exact.prevent="shiftEnter()"
									/>
								</v-col>
								<v-col cols="2" class="d-flex justify-center align-center pa-0">
									<v-btn fab small elevation="0" color="primary" @click="sendMessage()">
										<v-icon>mdi-send</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</div>
					</div>
				</div>
				<UserAddDialog v-if="showUserAddDialog" @reject="showUserAddDialog = false" @add-user="addUserToConversation" />
				<ChatNew v-if="newDialogOpen" :invoice-id="invoice._id" @close="newDialogOpen = false" @mail-created="mailUpdated" />
			</div>
		</v-slide-x-transition>
	</portal>
</template>

<script>
import {mapGetters} from 'vuex';
import utils from '@/utils';
import {createPaginationObject} from '@/utils/pagination';
import UserAddDialog from './user-add-dialog';
import ChatNew from './chat-new';

export default {
	components: {
		UserAddDialog,
		ChatNew
	},

	props: {
		invoice: {
			type: Object,
			default: () => ({})
		},

		mail: {
			type: Object,
			default: () => ({})
		},

		width: {
			type: String,
			default: '40%'
		},

		inboxOpen: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		showUserAddDialog: false,
		showRecipients: false,
		newDialogOpen: false,
		emojiMenu: false,

		mails: [],
		mailChunks: [],
		emojis: [...utils.emoji.list],

		message: '',
		name: '',
		invoiceName: '',
		activeInvoice: null,
		errorMessage: '',

		interval: undefined,

		lastMailId: -1,

		pagination: createPaginationObject({pageSize: 20}),

		referenceMail: null,
		owner: null,

		LEAVING_CONVERSATION_MESSAGE: 'LEAVING_CONVERSATION_MESSAGE',
		ADDING_CONVERSATION_MESSAGE: 'ADDING_CONVERSATION_MESSAGE'
	}),

	computed: {
		...mapGetters('userStore', ['getUserData']),
		...mapGetters('users', ['getUsers'])
	},

	watch: {
		async 'mail._id'() {
			this.mailChunks = [];
			this.lastMailId = -1;
			await this.fetchMails();
			await this.fetchInvoice();
		}
	},

	async created() {
		if(this.mail._id) {
			await this.fetchMails();

			if (this.mail.meta.invoice) {
				await this.fetchInvoice();
			}

			if (this.interval) {
				clearInterval(this.interval);
			}

			this.interval = setInterval(() => {
				this.fetchMails();
			}, 5000);
		}
	},

	beforeDestroy() {
		clearInterval(this.interval);
	},

	methods: {
		async mailUpdated(item) {
			this.mail = {...item};
			await this.fetchMails();
			await this.fetchInvoice();
			this.$emit('updated', item);
		},

		getSystemMessage(msg) {
			if (msg.isDatePost) {
				return msg.text;
			}

			if (msg.content === this.LEAVING_CONVERSATION_MESSAGE) {
				return `${this.getUsers.find(x => x.id === msg.from_user).name} lämnade konversationen.`;
			}

			if (msg.content.includes(this.ADDING_CONVERSATION_MESSAGE)) {
				return `${msg.content.replace(`${this.ADDING_CONVERSATION_MESSAGE} `, '')} gick med i konversationen.`;
			}

			return null;
		},

		shiftEnter() {
			this.message = `${this.message}\n`;
		},

		insertEmoji(e) {
			this.emojiMenu = false;
			this.message = `${this.message}${e}`;
		},

		openLeaveConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Lämna konversation',
				text: 'Är du säker på att du vill lämna konversationen?',
				confirmed: this.leaveConversation
			});
		},

		openArchiveDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Arkivera konversation',
				text: 'Är du säker på att du vill arkivera konversationen?',
				confirmed: this.archiveConvo
			});
		},

		async addUserToConversation(user) {
			await this.$service.mail.addUserToConversation(this.referenceMail._id, {
				users: [...this.referenceMail.recipients, user.id]
			});

			await this.$service.mail.answer(this.referenceMail._id, {
				duedate: this.referenceMail.duedate,
				requires_action: this.referenceMail.requires_action,
				content: `${this.ADDING_CONVERSATION_MESSAGE} ${user.name}`
			});

			await this.fetchMails();
		},

		async leaveConversation() {
			await this.$service.mail.answer(this.referenceMail._id, {
				content: this.LEAVING_CONVERSATION_MESSAGE,
				duedate: this.referenceMail.duedate,
				requires_action: this.referenceMail.requires_action
			});

			await this.$service.mail.leaveConversation(this.referenceMail._id);
			this.$emit('leave-chat');
		},

		async archiveConvo() {
			await this.$service.mail.archiveConversation(this.referenceMail._id);
		},

		async onChatScroll(event) {
			if (event.target.scrollTop === 0) {

				if (this.pagination.next()) {
					event.target.scrollTop = 10;
					await this.fetchMails({preventScrollBottom: true, fetchWithPagination: true});
				}
			}
		},

		async sendMessage() {
			await this.$service.mail.answer(this.referenceMail._id, {
				content: this.message,
				duedate: this.referenceMail.duedate,
				requires_action: this.referenceMail.requires_action
			});

			this.message = '';
			await this.fetchMails();
		},

		async fetchRootMail() {
			try {
				const data = await this.$service.mail.get(this.mail._id, {
					page: 1,
					pageSize: 1
				});

				this.referenceMail = data[0];
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async fetchMails(opts = {}) {
			await this.fetchRootMail();

			try {
				const data = await this.$service.mail.get(this.mail._id, {
					page: opts.fetchWithPagination ? this.pagination.page : 1,
					pageSize: this.pagination.pageSize,
					sort: [{
						sent_timestamp: -1
					}]
				});

				if(!data.length) {
					return;
				}

				data.reverse();

				const latestPost = data[0];

				if (latestPost._id === this.lastMailId) {
					return;
				}

				this.pagination.updateMaxPage(data._paginationTotal);
				this.name = this.referenceMail.subject;
				this.mailChunks[opts.fetchWithPagination ? this.pagination.page : 1] = data;
				this.mails = this.formatMailData(this.mailChunks);

				this.$service.mail.markMessageAsRead(this.mail._id);

				this.lastMailId = latestPost._id;

				if (!opts.preventScrollBottom) {
					this.$nextTick(() => {
						var container = this.$refs.chatbox;

						container.scrollTop = container.scrollHeight;
					});
				}
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async fetchInvoice() {
			try {
				const data = await this.$service.invoices.get(this.mail.meta.invoice);
				const ownerData = await this.$service.mail.owner(this.mail._id);

				this.owner = ownerData;
				this.activeInvoice = data;
				this.invoiceName = data.ID;

			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		formatMailData(data) {
			const getDayText = date => {
				if (utils.date.isToday(date)) {
					return 'Idag';
				}

				if (utils.date.isYesterday(date)) {
					return 'Igår';
				}

				return utils.date.getFullDate(date);
			};
			const getDateObject = date => ({
				id: date.getTime(),
				isDatePost: true,
				text: getDayText(date)
			});
			let dateCheck;

			return data.flat().slice().reverse().reduce((acc, curr) => {
				const d = utils.date.intToDate(curr.date);
				const t = curr.time.toString().length > 5 ? String(curr.time) : '0' + String(curr.time);
				const user = curr.recipients.find(y => curr.from_user === y.user);
				const msgObj = {
					...curr,
					_isYou: curr.from_user === this.getUserData.id,
					_avatar: user ? user.avatar : null,
					_date: d,
					_time: `${t.substring(0, 2)}:${t.substring(2, 4)}`
				};

				if (msgObj._date !== dateCheck) {
					dateCheck = msgObj._date;

					return [
						...acc,
						getDateObject(d),
						msgObj
					];
				}

				dateCheck = msgObj._date;

				return [
					...acc,
					msgObj
				];
			}, []);
		},

		getClasses(msg) {
			return {
				'd-flex': true,
				'my-6': true,
				'justify-end': msg._isYou
			};
		},

		getCardClasses(msg) {
			const isDark = this.$vuetify.theme.isDark;

			return {
				'primary': msg._isYou,
				'lighten-3': msg._isYou && !isDark
			};
		}
	}

};
</script>

<style lang="scss">
	.ChatMessage-textarea .v-text-field__details {
		display: none;
	}

	.ChatRight {
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		background: var(--v-backgroundSecondary-base);
		width: calc(100% - 60%);
		z-index: 9;
	}
</style>
