<template>
	<v-expansion-panels v-if="isUserAllowedToInvoiceHandle" v-model="panel" multiple>
		<v-expansion-panel>
			<v-expansion-panel-header class="text-button" style="line-height: 100%;">
				Momsbelopp <span class="ml-4 text-caption">Kvar att kontera: <strong class="accent--text">{{ $utils.format.price(vat_amountLeft) }}</strong></span>
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<vat-amount v-if="invoice.meta.vat"
					:invoice-id="invoice._id"
					:vat="invoice.meta.vat"
					:amount-left="vat_amountLeft"
					:total-tax="invoice.Amounts.TotalTax.Amount"
					@on-update="vat_amountLeft = $event"
					@on-save="$emit('accounting:updated');"
				/>
			</v-expansion-panel-content>
		</v-expansion-panel>

		<v-expansion-panel v-if="showKontering">
			<v-expansion-panel-header class="text-button" style="line-height: 100%;">
				Kontering <span class="ml-4 text-caption">Kvar att kontera: <strong class="accent--text">{{ $utils.format.price(amountLeft) }}</strong></span>
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<v-row>
					<v-col cols="3">
						<select-template ref="template" @input="onInput" />
					</v-col>
				</v-row>
				<AccountingRows v-model="data"
					:error="validationError"
					:date="defaultDate"
					:read-only="invoice._underPayment || invoice.meta.deleted"
					:amount-left="amountLeft"
					:counter-part="counterPart"
					:template-id="templateId"
					@reset="data = null"
					@add="addAccounting"
					@save="saveAccounting"
					@delete="deleteAccounting"
					@template="saveTemplate"
				/>
				<Loader :error="validationError" :success="updateFeedbackMessage" />
			</v-expansion-panel-content>
		</v-expansion-panel>


		<v-expansion-panel>
			<v-expansion-panel-header class="text-button" style="line-height: 100%;">
				Attestering
			</v-expansion-panel-header>

			<v-expansion-panel-content class="Attest">
				<v-data-table
					:headers="getAccountingHeaders"
					:items="getRows"
					:items-per-page="-1"
					:item-class="itemRowBackground"
					hide-default-footer
					disable-sort
					@click:row="onRowClick"
				>
					<template v-slot:item.select="{item}">
						<v-checkbox v-if="isAttestant(item)" v-model="markedRows[item._id]" @click.stop.prevent />
					</template>
					<template v-slot:item.status="{item}">
						<div class="d-flex">
							<div class="d-flex mr-1" :tabIndex="-1">
								<v-icon v-if="(item.attestants.approvals.length === item.approved_by.length) && item.approved_by.length !== 0" v-tooltip :content="getTooltip(item, 'approved')" color="success">
									mdi-checkbox-marked-circle
								</v-icon>
								<!-- <div v-else v-tooltip :content="getTooltip(item, 'approval')">
									<v-icon disabled>mdi-checkbox-blank-circle-outline</v-icon>
								</div> -->
								<div v-else>
									<div v-if="item.attestants.approvals.length > 0" v-tooltip :content="getTooltip(item, 'approval')">
										<v-icon disabled>mdi-checkbox-blank-circle-outline</v-icon>
									</div>
								</div>
							</div>

							<div class="d-flex" :tabIndex="-1">
								<v-icon v-if="(item.attestants.authorizations.length === item.authorized_by.length) && item.authorized_by.length !== 0" v-tooltip :content="getTooltip(item, 'authorized')" color="success">mdi-checkbox-marked-circle</v-icon>
								<!-- <div v-else v-tooltip :content="getTooltip(item, 'authorization')">
									<v-icon disabled>mdi-checkbox-blank-circle-outline</v-icon>
								</div> -->
								<div v-else>
									<div v-if="item.attestants.authorizations.length > 0" v-tooltip :content="getTooltip(item, 'authorization')">
										<v-icon disabled>mdi-checkbox-blank-circle-outline</v-icon>
									</div>
								</div>
							</div>
						</div>
					</template>

					<template v-for="(head, i) in headers" v-slot:[`item.${head}`]="{ item }">
						<div :key="head" v-tooltip :content="item.codetexts[i]" :tabIndex="-1">
							{{ getCodeValue(head, item.codes[i]) }}
						</div>
					</template>

					<template v-slot:item.period="{item}">
						<span v-if="item.period" :tabIndex="-1">{{ item.period[0] }} - {{ item.period[1] }}</span>
					</template>
					<template v-slot:item.note="{item}">
						<v-btn v-tooltip icon :disabled="item.note === ''" :content="item.note ? item.note : '-'" :tab-index="-1"
							@click="onRowClick(item)"
						>
							<v-icon v-if="item.note" small>mdi-note-outline</v-icon>
						</v-btn>
					</template>
					<template v-slot:item.amount="{item}" :tabIndex="-1">
						<strong class="primary--text">{{ $utils.format.price(item.amount) }}</strong>
					</template>
				</v-data-table>

				<div v-if="!invoice._underPayment" class="d-flex align-center mt-6">
					<v-btn :disabled="!canAttest || invoice.meta.deleted" small color="accent" @click="addToAttestList">Lägg på lista</v-btn>
					<v-spacer />
					<span class="mr-4 text-caption">Kvar att kontera: <strong class="accent--text">{{ $utils.format.price(amountLeft) }}</strong></span>
					<v-btn text color="green" :disabled="!canAttest || invoice.meta.deleted || error" @click.stop="openSign">Attestera</v-btn>
					<v-btn v-if="amountLeft <= 0" text color="green" :disabled="invoice.meta.deleted" @click.stop="showChooseAttestant = true">Välj Attestanter</v-btn>
				</div>

				<div v-else class="d-flex align-center mt-6">
					<v-spacer />
					<v-btn v-if="isUserAllowedToInvoiceAlterPostAttestation && (invoice.meta.payment_status === 'unpaid' || invoice.meta.payment_status === 'blocked')" text @click.stop="unlockAttest = true">Lås upp kontering och ta bort attester</v-btn>
				</div>

				<Loader :service="[$service.accounting.update,
					$service.accounting.delete,
					$service.invoices.accountingAdd]" hide-loading slim
				/>

				<Dialog v-if="signOpen" title="Signera" ok-btn-text="Sign" :loading="signPending" @confirm="completeAccounting"
					@reject="signOpen = false"
				>
					<v-text-field ref="passwordInput" v-model="password" label="Lösenord" type="password" @keydown.enter.prevent="completeAccounting" />
					<Loader :error="attestMessage" hide-loading slim />
				</Dialog>

				<Dialog v-if="unlockAttest" title="Lås upp" ok-btn-text="Bekräfta" @confirm="unlock" @reject="unlockAttest = false">
					Bekräfta att du vill låsa upp konteringen. (Detta innebär att attester och valda attestanter tas bort.)
					<Loader :error="errorMessage" :loading="isPending" hide-loading slim />
				</Dialog>

				<Loader :error="error" />
			</v-expansion-panel-content>
		</v-expansion-panel>

		<v-expansion-panel>
			<v-expansion-panel-header class="text-button" style="line-height: 100%;">
				Bokföring
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<Bookings :bookings="invoice.meta.bookings" />
			</v-expansion-panel-content>
		</v-expansion-panel>

		<choose-attestant v-if="showChooseAttestant"
			:invoice-id="invoice._id"
			:rows="invoice.meta.accounting"
			@on-close="showChooseAttestant = false"
			@on-submit="
				showChooseAttestant = false;
				$emit('accounting:updated');
			"
		/>
	</v-expansion-panels>
</template>

<script>
import {get as dotProp} from 'dot-prop';
import {mapActions, mapGetters} from 'vuex';
import { intToDate } from '@/utils/date';
import { run } from '@/utils/service';
import Dialog from '@/components/dialog';
import SelectTemplate from '@/components/select-template';
import AccountingRows from '@/components/accounting-rows';
import Bookings from './bookings';
import VatAmount from './vat-amount';
import ChooseAttestant from './choose-attestant';

export default {
	components: {
		Dialog,
		AccountingRows,
		SelectTemplate,
		Bookings,
		ChooseAttestant,
		VatAmount
	},

	props: {
		invoice: {
			required: true,
			type: Object,
			default: () => ({})
		},

		supplier: {
			type: Object,
			default: () => ({})
		},

		amountLeft: {
			type: String,
			default: '0'
		},

		showCodeTexts: {
			type: Boolean,
			default: true
		},

		showKontering: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		panel: [],
		dateMenu: false,
		bookingDateMenu: false,
		validationError: '',
		signOpen: false,
		unlockAttest: false,
		password: '',
		errorMessage: '',
		attestMessage: '',
		isPending: false,
		signPending: false,
		vat_amountLeft: '0',

		showChooseAttestant: false,
		updateFeedbackMessage: null,

		markedRows: {},


		headers: ['A', 'B', 'C', 'D', 'E', 'F','G', 'H', 'I', 'J'],


		itemToEdit: null,
		counterPart: null,

		defaultDate: null,

		data: null,

		templateId: null
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getCodeValue', 'getIsActiveCode']),
		...mapGetters('users', ['getUsers']),
		...mapGetters('userStore', ['getUserData', 'isUserAllowedToInvoiceAlterPostAttestation', 'isUserAllowedToInvoiceHandle']),

		getRows() {
			return this.invoice.meta.accounting.map(row => {
				return {
					...row,
					A: row.codes[0],
					B: row.codes[1],
					C: row.codes[2],
					D: row.codes[3],
					E: row.codes[4],
					F: row.codes[5],
					G: row.codes[6],
					H: row.codes[7],
					I: row.codes[8],
					J: row.codes[9],
					period: this.periodReverse(row.period_start, row.period_length),
					codetexts: row.codetexts,
					raw: row
				};
			});
		},

		showAccounting() {
			return this.amountLeft > 0;
		},

		canAttest() {
			return Object.values(this.markedRows).some(x => x);
		},

		getAccountingHeaders() {
			return [{
				text: '',
				value: 'select'
			}, {
				text: 'Status',
				value: 'status'
			}, this.getIsActiveCode(0) && {
				text: `${this.getCodeNames.A}`,
				value: 'A'
			}, this.getIsActiveCode(1) && {
				text: `${this.getCodeNames.B}`,
				value: 'B'
			}, this.getIsActiveCode(2) && {
				text: `${this.getCodeNames.C}`,
				value: 'C'
			}, this.getIsActiveCode(3) && {
				text: `${this.getCodeNames.D}`,
				value: 'D'
			}, this.getIsActiveCode(4) && {
				text: `${this.getCodeNames.E}`,
				value: 'E'
			}, this.getIsActiveCode(5) && {
				text: `${this.getCodeNames.F}`,
				value: 'F'
			}, this.getIsActiveCode(6) && {
				text: `${this.getCodeNames.G}`,
				value: 'G'
			}, this.getIsActiveCode(7) && {
				text: `${this.getCodeNames.H}`,
				value: 'H'
			}, this.getIsActiveCode(8) && {
				text: `${this.getCodeNames.I}`,
				value: 'I'
			}, this.getIsActiveCode(9) && {
				text: `${this.getCodeNames.J}`,
				value: 'I'
			}, {
				text: 'Period',
				value: 'period'
			}, {
				text: 'Belopp',
				value: 'amount',
				align: 'right'
			}, {
				text: '',
				value: 'note',
				align: 'center'
			}].filter(Boolean);
		}
	},

	watch: {
		'invoice._id'() {
			this.defaultDate = dotProp(this, 'invoice.meta.date.booking', 0);
			this.resetFormData();
			this.openPanels();
		},

		'invoice.meta.accounting'(to, from) {
			if (from.length !== to.length) {
				this.openPanels();
			}
		},

		supplier() {
			this.counterPart = this.supplier.counterpart;
		}
	},

	created() {
		this.defaultDate = dotProp(this, 'invoice.meta.date.booking', 0);
		this.openPanels();
		this.checkSettings();
	},

	methods: {
		...mapActions('accounting', ['fetchCodes']),
		...mapActions('globalAttest', ['addGlobalAttest']),

		addToAttestList() {
			Object.entries(this.markedRows).forEach(([key, value]) => {
				if (!value) {
					return;
				}

				const row = this.invoice.meta.accounting.find(x => x._id === key);

				this.addGlobalAttest({
					...row,
					invoiceId: this.invoice._id,
					sellerName: this.invoice.meta.supplier_name,
					invoiceNumber: this.invoice.meta.supplier_no
				});
			});
		},

		async checkSettings() {
			try {
				const data = await this.$service.invoices.checkSettings({
					throwException: true
				});

				console.log(data);
			} catch (error) {
				this.error = error.message;
			}
		},


		onInput(value) {
			this.data.codes = value ? value.codes.map(code => code) : [] ;
			this.templateId = value ? value._id : null;
		},


		openPanels() {
			this.panel.push(1, 2);
		},

		async openSign() {
			this.signOpen = true;
			await this.$nextTick();
			this.$refs.passwordInput.$el.querySelector('input').focus();
		},

		periodReverse(start, length) {
			const d = this.$utils.date.addMonths(intToDate(`${start}01`), length - 1);
			const endDate = `${this.$utils.date.getYear(d)}-${this.$utils.date.getMonth(d)}`;
			const s = String(start);

			if (start === 0 || length === null) {
				return null;
			}

			return [`${s.substring(0, 4)}-${s.substring(4, 6)}`, endDate];
		},

		isAttestant(item) {
			const userId = this.getUserData.id;
			const hasApproved = item.approved_by.some(x => x.id === userId);
			const hasAuth = item.authorized_by.some(x => x.id === userId);
			const approvals = this.invoice.meta.attestants.approvals.find(x => x.id === item._id);
			const authorizations = this.invoice.meta.attestants.authorizations.find(x => x.id === item._id);
			const isApproval = approvals && approvals.attestants.some(x => x.id === userId);
			const isAuth = authorizations && authorizations.attestants.some(x => x.id === userId);

			if ((hasApproved && isAuth && !hasAuth) ||
				(hasAuth && isApproval && !hasApproved)) {
				return true;
			}

			if ((isApproval && !hasApproved) || (isAuth && !hasAuth)) {
				return true;
			}

			return false;
		},

		getUser(id) {
			return this.getUsers.find(x => x.id === id);
		},

		getTooltip(item, type) {
			let users = [];
			let names = '';
			let count = 0;

			if (type === 'approval') {
				users = item.attestants.approvals;
				count = item.approved_by.length;
			}

			if (type === 'approved') {
				users = item.approved_by;
				count = item.approved_by.length;
			}

			if (type === 'authorized') {
				users = item.authorized_by;
				count = item.authorized_by.length;
			}

			if (type === 'authorization') {
				users = item.attestants.authorizations;
				count = item.authorized_by.length;
			}

			users.forEach(user => {
				const u = this.getUser(user.id);

				if (!u) {
					return;
				}

				names = `${names} ${u.name} (${u.login}) \n`;

			});

			if (type === 'approval'|| type === 'approved') {
				return `
					<h5 class="text-overline">Godkännande ${count}/${item.attestants.approvals.length}</h5>
					${names}
				`;
			}

			if (type === 'authorization' || type === 'authorized') {
				return `
					<h5 class="text-overline">Beslutande ${count}/${item.attestants.authorizations.length}</h5>
					${names}
				`;
			}
		},

		itemRowBackground(item) {
			return item._id === (this.data && this.data._id) && 'active-accounting-row';
		},

		resetFormData() {
			this.data = null;

			this.validationError = '';
		},

		onRowClick(item) {
			if (!this.panel.includes(0)) {
				this.panel.push(0);
			}

			this.data = {...item.raw};
			this.validationError = '';
		},

		validate() {
			return new Promise((resolve, reject) => {
				if (!this.data.date) {
					reject('Felaktigt bokföringsdatum');
				}

				this.$service.invoices.accountingValidate.call({
					id: this.invoice._id,
					body: this.data
				}).then(data => {
					if (data._error) {
						reject(data._error);

						return;
					}

					resolve(true);
				});
			});
		},

		saveTemplate() {
			this.$refs.template.fetchTemplates();
			this.resetFormData();
		},

		async saveAccounting() {
			this.$refs.template.fetchTemplates();
			try {
				await this.validate();
			} catch (error) {
				this.validationError = error;

				return;
			}

			try {
				await this.$service.accounting.update(this.data._id, {
					...this.data
				});

				this.resetFormData();
				this.$emit('accounting:updated');
				this.updateFeedbackMessage = ['Kontering sparad'];
			} catch (error) {
				this.validationError = error;
			}
		},

		async deleteAccounting() {
			try {
				run(await this.$service.accounting.delete.call({
					id: this.data._id
				}), () => {
					this.resetFormData();
					this.$emit('accounting:updated');
				});
				this.updateFeedbackMessage = ['Kontering borttagen'];
			} catch (error) {
				this.validationError = error;
			}
		},

		async addAccounting() {
			try {
				await this.validate();
			} catch (error) {
				this.validationError = error;

				return;
			}
			try {
				await run(await this.$service.invoices.accountingAdd.call({
					id: this.invoice._id,
					body: this.data
				}), () => {
					this.resetFormData();
					this.$emit('accounting:updated');
					this.updateFeedbackMessage = ['Kontering tillagd'];
				});
			} catch (error) {
				this.validationError = error;
			}

			this.resetFormData();
		},

		async unlock() {
			this.errorMessage = '';
			try {
				await this.$service.invoices.unlock(this.invoice._id, {}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});

				this.unlockAttest = false;
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async completeAccounting() {
			try {
				await this.$service.accounting.signRows({
					password: this.password,
					ids: Object.entries(this.markedRows).map(([key, value]) => {
						if (value) {
							return key;
						}
					}).filter(Boolean)
				}, {
					throwException: true,
					loading: val => {
						this.signPending = val;
					}
				});

				this.password = '';
				this.markedRows = {};
				this.signOpen = false;
				this.$emit('accounting:updated');

			} catch (error) {
				this.password = '';
				this.attestMessage = error.message;
			}
		}


	}
};
</script>

<style lang="scss">
.active-accounting-row {
	background-color: var(--v-primary-lighten2);
}

.theme--dark {
	.active-accounting-row {
		background-color: var(--v-primary-darken3);
	}
}

</style>
