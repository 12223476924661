<template>
	<div>
		<div class="d-flex justify-end">
			<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
				@click="exportExcel"
			>
				<v-icon>mdi-microsoft-excel</v-icon>
			</v-btn>
		</div>
		<Loader :error="errorMessage" :loading="isForwardPending" class="mb-4" />


		<RegisterListTodo ref="registerList" :items="todos" :headers="headers" sort-by="value" sidebar-width="65%"
			:loading="isPending"
			sidebar-no-footer item-key="_id"
			:pagination="pagination"
			:show-sidebar="activeInvoice !== null || activePayment !== null"
			@active-item-changed="setActiveItem"
			@pagination-update="updatePagination"
			@mass-action="doMassAction"
			@on-filter-update="onFilterUpdate"
		>
			<InvoiceSidebar v-if="activeInvoice" :invoice="activeInvoice" :preview="true" @on-refetch="fetchTodos" />
			<EditInactiveUser v-if="activeUser" :user-to-edit="activeUser" @end-user="fetchTodos" />
			<SubscriptionDetailed v-if="activeSubscription" :subscription-id="activeSubscription._id" @on-archive="fetchTodos" />
			<PaymentSidebar :invoice="activePayment" @on-refetch="fetchTodos" @close-fetch="fetchTodos" />
		</RegisterListTodo>

		<Loader :error="errorMessage" :loading="isForwardPending" />


		<Dialog v-if="forwardDialog" title="Välj användare" ok-btn-text="Välj" :disable-ok-btn="!selectedForwardUser"
			@reject="forwardDialog = false" @confirm="forwardTodos"
		>
			<ui-autocomplete v-model="selectedForwardUser" :items="getUsers" label="Användare" item-text="_userName" item-value="id" />
		</Dialog>

		<Dialog v-if="sensitivityDialog"
			:alert="!isUserAllowedToHandleManageSensitiveInvoices"
			title="Denna faktura är sekretessklassad"
			ok-btn-text="Ja"
			@confirm="setActiveSensitivityItem"
			@reject="sensitivityDialog = false"
		>
			<div class="mb-8">
				<div v-if="!isUserAllowedToHandleManageSensitiveInvoices">Du saknar behörighet att se denna faktura</div>
				<div v-if="isUserAllowedToHandleManageSensitiveInvoices" class="text-subtitle-1">
					Fakturan har sekretessklassning <strong>{{ sensitivityLevel }}</strong>, vill du visa faktura?
				</div>
			</div>
		</Dialog>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import {run} from '@/utils/service';
import RegisterListTodo from '@/components/register-list-todo';
import InvoiceSidebar from '@/views/invoices/children/invoices/components/sidebar';
import EditInactiveUser from '@/views/administration/children/inactive-users/components/edit-inactive-user';
import SubscriptionDetailed from '@/views/register/children/subscriptions/components/subscription-detailed';
import PaymentSidebar from '@/views/payments/children/awaiting-payments/components/sidebar';


export default {
	components: {
		RegisterListTodo,
		InvoiceSidebar,
		EditInactiveUser,
		SubscriptionDetailed,
		PaymentSidebar
	},

	data: () => ({
		todos: [],
		activeInvoice: null,
		activeUser: null,
		activeSubscription: null,
		activeSensitivityInvoice: null,
		activePayment: null,
		sensitivityLevel: '',

		forwardDialog: false,
		forwardData: null,
		isForwardPending: false,
		selectedForwardUser: null,
		sensitivityDialog: null,

		isPending: false,

		filter: {},

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: [],
			sortDesc: []
		},
		errorMessage: '',

		headers: [{
			text: 'Ärende',
			value: 'todo_type',
			filter: {
				type: 'select',
				items: [{
					text: 'Faktura',
					value: 'invoice'
				}, {
					text: 'Abonnemang',
					value: 'subscription'
				}, {
					text: 'Användare',
					value: 'user'
				}]
			}
		}, {
			text: 'Datum',
			value: 'creation_date'
		}, {
			text: 'ID',
			value: 'id',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Namn',
			value: 'name',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Belopp inkl. moms',
			value: 'amount',
			align: 'right'
		}, {
			text: 'Avsändare',
			value: 'from',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Hantera senast',
			value: 'deadline'
		}, {
			text: 'Aktivitet',
			value: 'todo',
			filter: {
				type: 'select',
				items: [{
					text: 'Kontera',
					value: 'ac'
				}, {
					text: 'Godkänn kontering',
					value: 'acap'
				}, {
					text: 'Beslutsattestera',
					value: 'acau'
				}, {
					text: 'Godkänn betalning',
					value: 'pay'
				}, {
					text: 'Hantera abonnemangsfel',
					value: 'serr'
				}, {
					text: 'Godkänn kostnadskontering',
					value: 'cap'
				}, {
					text: 'Beslutsattestera kostnadskontering',
					value: 'cau'
				}, {
					text: 'Godkänn momskontering',
					value: 'vap'
				}, {
					text: 'Beslutsattestera momskontering',
					value: 'vau'
				}, {
					text: 'Hantera avslutad användare',
					value: 'hi'
				}]
			}
		}, {
			text: 'Utredning',
			value: 'meta.investigation'
		}, {
			text: '',
			value: 'misc'
		}]
	}),

	computed: {
		...mapGetters('userStore', ['getUser', 'isUserAllowedToHandleManageSensitiveInvoices']),
		...mapGetters('userStore', ['getUserData']),
		...mapGetters('users', ['getUsers'])
	},

	created() {
		this.fetchTodos();
	},

	methods: {
		setActiveItem(item) {
			this.activeInvoice = null;
			this.activeUser = null;
			this.activeSubscription = null;
			this.activePayment = null;
			this.markAsRead([item._id]);

			if(item.todo === 'pay') {
				this.activePayment = {
					_id: item.reference
				};

				return;
			}


			if(item.todo_type === 'invoice') {
				if(item.meta.sensitivity > 0) {
					this.sensitivityLevel = item.meta.sensitivity === 1 ? 'utskrift': 'visning';
					this.activeSensitivityInvoice = item;
					this.sensitivityDialog = true;

					return;
				}

				this.activeInvoice = {
					_id: item.reference
				};
			} else if (item.todo_type === 'user') {
				this.activeUser = {
					id: item.reference,
					name: item.meta.name,
					login: item.meta.login,
					avatar: null
				};
			} else if(item.todo_type === 'subscription') {
				this.activeSubscription = {
					_id: item.reference
				};
			}
		},

		setActiveSensitivityItem() {
			this.activeInvoice = {
				_id: this.activeSensitivityInvoice.reference
			};
			this.sensitivityDialog = false;
		},

		async markAsRead(items) {
			run(await this.$service.todo.read.call({
				rawBody: items
			}), () => {
				this.fetchTodos();
			});
		},

		async markAsUnread(items) {
			run(await this.$service.todo.unread.call({
				rawBody: items
			}), () => {
				this.fetchTodos();
			});
		},

		async forwardTodos() {
			this.errorMessage = '';

			const id = this.selectedForwardUser;
			const rawBody = this.forwardData.items.map(x => x._id);

			try{
				await this.$service.todo.forward({
					uid: id,
					todos: [...rawBody]
				},{
					throwException: true,
					loading: val => {
						this.isForwardPending = val;
					}
				});
			} catch(error) {
				this.errorMessage = error.message;
			}

			this.fetchTodos();
			this.selectedForwardUser = null;
			this.forwardData = null;
			this.forwardDialog = false;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchTodos();
		},

		async exportExcel() {
			this.isPending = true;
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.todo.search({
				pageSize: 0,
				excel: true,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					user: this.getUserData.id
				}
			});
			const excell = data.map(d => {

				return {
					'Dokumenttyp': d.todo_type

					// 'Betalningsmottagare': d.meta ? d.meta.supplier_name : '',
					// 'Fakturanr': d.meta ? d.meta.invoice_name: '',
					// 'Verifikationsnr': d.meta ? d.meta.verification_no : '',
					// 'Fakturastatus': d.meta ? d.meta.type : '',
					// 'Belopp': d.amount
				};
			});

			await this.$utils.excel.download(excell, 'Att göra');
			this.isPending = false;
		},

		onFilterUpdate(filter) {
			this.filter = filter;
			this.fetchTodos();
		},

		async fetchTodos() {
			this.isPending = true;
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.todo.search({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					user: this.getUserData.id
				}
			}, {
				loading: val => {
					this.isPending = val;
				},
				throwException: true
			});

			this.pagination.total = data._paginationTotal;
			this.todos = data.map(d => ({
				...d,
				deadline: d.deadline ? this.$utils.date.intToPretty(d.deadline) : '',
				creation_date: d.creation_date ? this.$utils.date.intToPretty(d.creation_date) : '',
				amount: d.amount ? this.$utils.format.price(d.amount) : '',
				meta: {
					...d.meta,
					not_ok: d.meta.formal ? d.meta.formal.not_ok : null,
					unknown: d.meta.formal ? d.meta.formal.unknown : null
				}
				// _from: this.getUsers.find(x => x.id == from)
			}
			));
		},

		doMassAction(data) {
			if (data.type === 'markAsRead') {
				this.markAsRead(data.items.map(x => x._id));

				return;
			}

			if (data.type === 'markAsUnread') {
				this.markAsUnread(data.items.map(x => x._id));

				return;
			}

			if (data.type === 'forward') {
				this.forwardDialog = true;
				this.forwardData = {...data};
			}
		}
	}
};
</script>
