<template>
	<div style="height: 100%;">
		<portal to="sidebar-right-title">
			{{ id }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-tooltip class="ml-2" fab text small
					:disabled="!attachments || attachments.meta.attachments.length === 0"
					content="Visa fakturabilaga" @click.stop="previewOpen = true"
				>
					<v-icon>mdi-paperclip</v-icon>
				</v-btn>
				<v-btn v-tooltip class="ml-2" fab text small
					content="Arkivera faktura" @click.stop="openArhiveConfirmDialog"
				>
					<v-icon>mdi-package-down</v-icon>
				</v-btn>

				<v-btn v-tooltip class="ml-2" fab text small
					color="primary"
					content="Skicka på ny kontroll" @click.stop="onRetryClick"
				>
					<v-icon>mdi-send</v-icon>
				</v-btn>
			</div>
		</portal>

		<Loader :error="fetchError" :loading="isFetchPending" />

		<v-expansion-panels v-if="invoice" v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Fakturauppgifter
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<invoice-info v-if="invoice.invoice.ID" :invoice="invoice.invoice" />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel v-for="(errorCode, i) in invoice.invoice.meta.error_codes" :key="errorCode">
				<v-expansion-panel-header class="text-caption" style="line-height: 100%;">
					<div>
						Felkod: <span class="ml-2 warning--text">{{ errorCode }}</span>
						<span class="ml-2 font-italic">{{ invoice.invoice.errors[i] }}</span>
					</div>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-alert v-if="!supportedErrors.includes(errorCode.toLowerCase())" type="error" dense outlined>
						Vi kan inte hantera denna felkod för tillfället.
					</v-alert>
					<component :is="errorCode.toLowerCase()" v-else :invoice="invoice.invoice" @on-invoice-update="onInvoiceUpdate" />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<invoice-preview v-if="attachments && previewOpen" :key="`${invoice._id}`" width="60%" :default-view="attachments.meta.attachments[0].type" :invoice.sync="attachments"
			:invoice-id="invoice._id"
			@on-close="previewOpen = false"
		/>
	</div>
</template>

<script>
import invoicePreview from '@/components/invoice-preview';
import invoiceInfo from '../../invoices/components/info';

import l01 from './error-l01';
import l02 from './error-l02';
import l03 from './error-l03';
import l04 from './error-l04';
import r02 from './error-r02';
import r03 from './error-r02';
import o02 from './error-o02';
import f06 from './error-f06';
import f01 from './error-f01';
import f03 from './error-f01';
import f04 from './error-f04';
import f07 from './error-f07';
import f08 from './error-f08';
import f09 from './error-f09';

export default {
	components: {
		invoicePreview,
		invoiceInfo,
		l01,
		l02,
		l03,
		l04,
		r02,
		r03,
		o02,
		f06,
		f01,
		f03,
		f04,
		f07,
		f08,
		f09
	},

	props: {
		id: {
			type: String,
			required: true
		}
	},

	data: () => ({
		panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ,10],
		supportedErrors: ['l01', 'l02', 'l03', 'l04', 'r02', 'r03', 'o02', 'f06', 'f01', 'f03', 'f04', 'f07', 'f08', 'f09'],
		previewOpen: true,
		invoice: null,
		fetchError: '',
		attachments: null,

		isPending: false,
		isFetchPending: false,
		isArchivePending: false
	}),

	watch: {
		async id() {
			await this.fetchFaultyInvoice();
			await this.fetchAttachments();
		}
	},

	async created() {
		await this.fetchFaultyInvoice();
		await this.fetchAttachments();
	},

	methods: {
		openArhiveConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Arkivera felaktig faktura',
				text: `Är du säker på att du vill arkivera fakturan med id ${this.id}?`,
				showMessage: true,
				confirmed: this.archiveInvoice
			});
		},

		async onInvoiceUpdate(updatedInvoice) {
			await this.$service.invoices.updateErrornous(this.invoice._id, updatedInvoice);
			this.fetchFaultyInvoice();
		},

		async archiveInvoice(params) {
			if (!params.message) {
				throw 'Du måste fylla i meddelande.';
			}

			await this.$service.invoices.archiveErrornous(this.id, {
				message: params.message
			}, {
				throwException: true,
				loading: val => {
					this.isArchivePending = val;
				}
			});

			this.$emit('on-archive');
		},

		async onRetryClick() {
			this.error = '';

			try {
				await this.$service.invoices.retryErrornous(this.id, {
					headers: {
						'Content-Type': 'application/xml'
					},
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});
			} catch (error) {
				this.error = error.message;
			}

			this.$emit('retry');
		},

		async fetchFaultyInvoice() {
			try {
				this.invoice = await this.$service.invoices.getErrornous(this.id, {
					throwException: true,
					loading: val => {
						this.isFetchPending = val;
					}
				});
			} catch (error) {
				this.fetchError = error.message;
			}
		},

		async fetchAttachments() {
			const data = await this.$service.invoices.getErrornousAttachments(this.id);

			this.attachments = {
				meta: {
					attachments: data
				}
			};
		}
	}
};
</script>

<style>
	.vue-codemirror,
	.CodeMirror {
		height: 100%;
	}
</style>
