<template>
	<v-app-bar app color="primary">
		<v-toolbar-title class="white--text pl-2">{{ title }}</v-toolbar-title>
		<v-spacer />

		<v-card flat class="GroupCard mr-4" color="primary lighten-1" dark>
			<global-attest-btn @click="showGlobalAttest = true" />
		</v-card>
		<v-card flat class="GroupCard pa-1 px-4 mr-4" color="primary lighten-1" dark @click="onSiteClick">
			<div class="text-caption">Organisation</div>
			<div class="text-caption font-weight-medium">{{ getSite.title }}</div>
		</v-card>
		<v-card flat class="GroupCard pa-1 px-4" color="primary lighten-1" dark @click="showGroupChangeDialog = true">
			<div class="text-caption">Grupp</div>
			<div class="text-caption font-weight-medium">{{ getUserGroup.name }}</div>
		</v-card>

		<Dialog v-if="showChangeSiteDialog" :title="$t('sidebar.change_org')" @confirm="onSiteChange"
			@reject="showChangeSiteDialog = false"
		>
			<ui-select v-model="selectedSite" :items="sitesList" item-value="id" item-text="title"
				:label="$t('sidebar.choose_org')" outlined
			/>
			<Loader :loading="changeSitePending" :error="changeSiteError" />
		</Dialog>

		<Dialog v-if="showGroupChangeDialog" title="Byt aktiv grupp" @confirm="onGroupChange"
			@reject="showGroupChangeDialog = false"
		>
			<ui-select v-model="selectedGroup" label="Välj aktiv grupp" :items="newUserGroups" item-text="name"
				item-value="_id"
			>
				<template v-slot:item="{ item }">
					<span v-if="item._hasParent" :class="item._hasGrand ? 'ml-10' : 'ml-5'">{{ item.name }}</span>
					<span v-else>{{ item.name }}</span>
				</template>
			</ui-select>
		</Dialog>

		<global-attest-dialog v-if="showGlobalAttest" @reject="showGlobalAttest = false" />
	</v-app-bar>
</template>

<script>
import Dialog from '@/components/dialog';
import {mapGetters, mapActions} from 'vuex';
import globalAttestBtn from '@/components/global-attest-btn';
import globalAttestDialog from '@/components/global-attest-dialog';

export default {
	name: 'Header',

	components: {
		Dialog,
		globalAttestBtn,
		globalAttestDialog
	},

	props: {
		title: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		selectedSite: null,
		selectedGroup: null,

		showGlobalAttest: false,
		showChangeSiteDialog: false,
		changeSitePending: false,
		changeSiteError: '',

		showGroupChangeDialog: false
	}),

	computed: {
		...mapGetters('userStore', ['getUser', 'getUserData', 'getUserGroups', 'getUserGroupId']),
		...mapGetters('sitesStore', ['getSites']),

		getUserGroup() {
			const group = this.getUserGroups.find(g => g._id === this.getUserGroupId);

			return group || {};
		},

		newUserGroups() {
			const items = this.getUserGroups.map(g => {
				if(g.parent) {
					const parent = this.getUserGroups.find(x => x._id === g.parent);

					if(parent.parent != null) {
						return {
							...g,
							_hasParent: g.parent != null ? true : false,
							_hasGrand: true
						};
					}

					return {
						...g,
						_hasParent: g.parent != null ? true : false
					};
				}

				return {
					...g,
					_hasParent: g.parent != null ? true : false
				};
			});

			return items;
		},

		getSite() {
			const site = this.getSites.find(s => s.id == this.getUser.tokenData.siteID);

			return site || {};
		},

		sitesList() {
			const list = this.getSites.map(s => ({
				...s,
				title: !s.is_active ? `${s.title} (Behörighet saknas)` : s.title,
				disabled: !s.is_active
			}));

			return list || [];
		}
	},

	created() {
		this.selectedSite = this.getUser.tokenData.siteID;

		if (this.getUserGroupId) {
			this.selectedGroup = this.getUserGroupId;
		}

		if (!this.getUserGroupId && this.getUserData.groups.length !== 0) {
			this.onGroupChange(this.getUserData.groups[0]._id);
		}
	},

	methods: {
		...mapActions('userStore', ['changeSite', 'setUser']),

		async onGroupChange() {
			const data = await this.$service.token.setGroup(this.selectedGroup);

			this.setUser(data);
			this.showGroupChangeDialog = false;
		},

		async onSiteChange() {
			try {
				const data = await this.$service.token.setSite(this.selectedSite, {}, {
					throwException: true,
					loading: val => {
						this.changeSitePending = val;
					}
				});

				this.setUser(data);
				window.location.reload();
			} catch (error) {
				this.changeSiteError = error.message;
			}
		},

		onSiteClick() {
			this.changeSiteError = '';
			this.showChangeSiteDialog = true;
		}
	}
};
</script>

<style lang="scss" scoped>
div.GroupCard {
	cursor: pointer;
	transition: all ease-in 0.1s;

	&:hover {
		transition: all ease-in 0.1s;
		background-color: var(--v-primary-lighten2) !important;
	}

	&:focus {
		transition: all ease-in 0.1s;
		background-color: var(--v-primary-lighten2) !important;
	}
}
</style>
